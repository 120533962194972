body,
html {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}
.App {
  display: flex;
  flex-direction: column;
}
.selected-link {
  color: #000;
}

@media only screen and (max-width: 600px) {
  .etki-logo {
    width: 100%;
    height: auto;
  }
  .change .bar1 {
    transform: rotate(-45deg) translate(-6px, 7.2px);
  }
  .change .bar2 {
    opacity: 0;
  }
  .change .bar3 {
    transform: rotate(45deg) translate(-3px, -7.2px);
  }
  .header-links,
  .footer-links {
    display: none;
  }
  .burguer-menu {
    display: block;
  }
  .inverse-card {
    flex-direction: column-reverse !important;
  }
  .video-wrapper {
    display: none !important;
  }

  .etik-btn {
    font-size: 0.7em !important;
  }
}
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 1){
  .inverse-card{
    flex-direction: column-reverse;
  }
}
